import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store'

const routes = [
  {
    name: 'login',
    path: '/login',
    component: () => import('./view/login'),
    meta: {
      title: 'login',
    },
  },
  {
    name: 'home',
    path: '/',
    component: () => import('./view/home'),
    meta: {
      title: '微聊',
      tabbarShow: true,
      isLogin: true
    },
  },
  // 新的好友
  {
    name: 'newFriends',
    path: '/newFriends',
    component: () => import('./view/home/newFriends.vue'),
    meta: {
      title: '新的好友',
      tabbarShow: true,
      isLogin: true
    },
  },
  {
    name: 'SettingCenter',
    path: '/SettingCenter',
    component: () => import('./view/home/SettingCenter.vue'),
    meta: {
      title: '设置中心',
      tabbarShow: true,
      isLogin: true
    },
  },
];
// 路由模式 createWebHashHistory()
const router = createRouter({
  routes,
  history: createWebHistory(),
});
// 路由收尾
router.beforeEach((to, from, next) => {
  // 在每次路由切换前，判断最新版本号
  store.dispatch('isNewVersion');
  // 网页标题
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = store.state.userInfo.nickName || title;
  }
  if(to.matched.some(res=>res.meta.isLogin)){
    if (sessionStorage['sessionId']) {
      next();
    }else{
      next({
        path:"/login",
        query:{
          redirect:to.fullPath
        }
      });
    }
  }else{
    next()
  }
});

export { router };
