import { router } from '../../router.js'
import Toast from '../../components/Toast/toast.js';
import { openDB } from 'idb';
import {
  getChatList,
  getChatUserFriends,
  getChatGroups,
  chatGroupUserInfo,
  friendApplyRecord,
  groupApplyReviewCount,
} from "@/api/home";
const ws = {
  db: null,
  namespaced: true,
  state: {
    // websocket地址
    wsUrl: process.env.VUE_APP_API_WS || `wss://${window.location.host}/ws`,
    // wsUrl: "wss://im.abc123.me/ws",
    // ws状态
    wsStatus: 0,// 0:正在连接 1: 已连接成功
    // websocket相关
    socketObj: "", // websocket实例对象
    //心跳检测
    timeout: 10000, // 超时时间
    timeoutObj: null, // 计时器对象——向后端发送心跳检测
    serverTimeoutObj: null, // 计时器对象——等待后端心跳检测的回复
    socketReconnectTimer: null, // 计时器对象——重连
    socketReconnectLock: false, // WebSocket重连的锁
    socketLeaveFlag: false, // 离开标记（解决 退出登录再登录 时出现的 多次相同推送 问题，出现的本质是多次建立了WebSocket连接）

    chatOpen: false, // 聊天窗口open状态
    chatUserFriends: [], // 私聊列表
    chatGroups: [], // 群聊列表
    chatGroupUserInfoList: [], // 群聊用户信息
    chatList: [], // 聊天列表
    total: 0, // 聊天列表总条数
    chatListFrom: {
      pageNum: 1,
      pageSize: 20,
      finished: true,
      refreshing: false,
      loading: false,
    },
    chatData: {}, //聊天对象
    newFriendslist: [], // 新的好友列表
    serverTimeObj: null,
    serverTime: 0, // 服务器时间
    groupApplyReviewCount: 0,

    // 表情
    emojis: [
      '[微笑]', '[撇嘴]', '[色]', '[发呆]', '[得意]', '[流泪]', '[害羞]', '[闭嘴]',
      '[睡]', '[大哭]', '[尴尬]', '[发怒]', '[调皮]', '[呲牙]', '[惊讶]', '[难过]',
      '[酷]', '[冷汗]', '[抓狂]', '[吐]', '[偷笑]', '[愉快]', '[白眼]', '[傲慢]',
      '[饥饿]', '[困]', '[惊恐]', '[流汗]', '[憨笑]', '[悠闲]', '[奋斗]', '[咒骂]',
      '[疑问]', '[嘘]', '[晕]', '[疯了]', '[衰]', '[骷髅]', '[敲打]', '[再见]',
      '[擦汗]', '[抠鼻]', '[鼓掌]', '[糗大了]', '[坏笑]', '[左哼哼]', '[右哼哼]', '[哈欠]',
      '[鄙视]', '[委屈]', '[快哭了]', '[阴险]', '[亲亲]', '[吓]', '[可怜]', '[菜刀]',
      '[西瓜]', '[啤酒]', '[篮球]', '[乒乓]', '[咖啡]', '[饭]', '[猪头]', '[玫瑰]',
      '[凋谢]', '[嘴唇]', '[爱心]', '[心碎]', '[蛋糕]', '[闪电]', '[炸弹]', '[刀]',
      '[足球]', '[瓢虫]', '[便便]', '[月亮]', '[太阳]', '[礼物]', '[拥抱]', '[强]',
      '[弱]', '[握手]', '[胜利]', '[抱拳]', '[勾引]', '[拳头]', '[差劲]', '[爱你]',
      '[NO]', '[OK]', '[爱情]', '[飞吻]', '[跳跳]', '[发抖]', '[怄火]', '[转圈]',
      '[磕头]', '[回头]', '[跳绳]', '[投降]', '[Hooray]', '[Meditate]', '[Smooch]', '[TaiChi L]',
      '[TaiChi R]'
    ],
    emojis2: [
      "😀", "😁", "😂", "🤣", "😃", "😄", "😅", "😆", "😉", "😊", "😋", "😎",
      "😍", "😘", "😗", "😙", "😚", "🙂", "🤗", "🤔", "😐", "😑", "😶", "🙄",
      "😏", "😣", "😥", "😮", "🤐", "😯", "😪", "😫", "😴", "😌", "🤓", "😛",
      "😜", "😝", "🤤", "😒", "😓", "😔", "😕", "🙃", "🤑", "😲", "🙁", "😖",
      "😞", "😟", "😤", "😢", "😭", "😦", "😧", "😨", "😩", "😬", "😰", "😱",
      "😳", "😵", "😡", "😠", "😇", "🤠", "🤡", "🤥", "😷", "🤒", "🤕", "🤢",
      "🤧", "😈", "👿", "👹", "👺", "💀", "👻", "👽", "👾", "🤖", "💩", "😺",
      "😸", "😹", "😻", "😼", "😽", "🙀", "😿", "😾"
    ],
  },

  mutations: {
    SET_serverTime(state, time) {
      state.serverTime = time;
      clearInterval(state.serverTimeObj);
      state.serverTimeObj = setInterval(() => {
        state.serverTime += 1000;
      }, 1000);
    },
    SET_groupApplyReviewCount: (state, count) => {
      state.groupApplyReviewCount = count || 0;
    },
    SET_chatOpen: (state, chatOpen) => {
      state.chatOpen = chatOpen;
    },
    SET_newFriendslist: (state, data) => {
      state.newFriendslist = data;
    },
    SET_chatData: (state, data) => {
      if(data.listType) { // 群聊
        for(let k of state.chatGroups) {
          if(k.id === data.listValue || k.id ===data.id) {
            data.joinType = k.joinType;
            data.forbiddenFlag = k.forbiddenFlag;
          }
        }
      }
      state.chatData = data;
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_chatList: (state, chatList) => {
      // for(let n of chatList){
      //   for (let k of state.chatUserFriends) {
      //     if(k.id == n.listValue && !n.listType){
      //       n.listName = k.nickName;
      //     }
      //   }
      // }
      state.chatList = chatList;
    },
    SET_chatUserFriends: (state, chatUserFriends) => {
      for (let k of chatUserFriends) {
        for (let n of state.chatList) {
          if (k.id == n.listValue && !n.listType) {
            n.listName = k.nickName;
          }
        }
      }
      state.chatUserFriends = chatUserFriends || [];
    },
    SET_chatGroups: (state, chatGroups) => {
      state.chatGroups = chatGroups || [];
    },
    SET_chatGroupUserInfoList: (state, data) => {
      for (let n of data) {
        n.remarkName = '';
        for (let k of state.chatUserFriends) {
          if (k.remarkName && n.userId == k.id) {
            n.remarkName = k.remarkName;
          }
        }
      }
      state.chatGroupUserInfoList = data || [];
    },
  },
  getters: {
    chatId(state) {
      return state.chatData.listValue || state.chatData.id;
    },
  },
  actions: {
    // websocket启动
    createWebSocket({ commit, state, dispatch }) {
      let webSocketLink = state.wsUrl; // webSocket地址
      // console.log(webSocketLink);
      try {
        if ("WebSocket" in window) {
          state.socketObj = new WebSocket(webSocketLink);
        } else if ("MozWebSocket" in window) {
          state.socketObj = new MozWebSocket(webSocketLink);
        }
        // websocket事件绑定
        dispatch('socketEventBind');
      } catch (e) {
        console.log("catch" + e);
        // websocket重连
        dispatch('socketReconnect');
      }
    },
    // websocket事件绑定
    socketEventBind({ commit, state, dispatch }) {
      // 连接成功建立的回调
      state.socketObj.onopen = function (event) {
        console.log("WebSocket:succeed");
        state.wsStatus = 1; //已连接
        dispatch('denglu');
        // 心跳检测重置
        dispatch('start');
      };
      // 连接发生错误的回调
      state.socketObj.onerror = function (event) {
        console.log("WebSocket:error");
        // websocket重连
        dispatch('socketReconnect');
      };
      // 连接关闭的回调
      state.socketObj.onclose = function (event) {
        console.log("WebSocket:close");
        // 心跳检测重置
        dispatch('reset');
        if (!state.socketLeaveFlag) {
          // 没有离开——重连
          // websocket重连
          dispatch('socketReconnect');
        }
      };
      // 向后端发送数据的回调
      state.socketObj.onsend = function () {
        // console.log("WebSocket:发送信息给后端");
      };
      // 接收到消息的回调
      state.socketObj.onmessage = function (msg) {
        let data = JSON.parse(msg.data);
        if (data.protocol === 'pong') {
          commit("SET_serverTime", +new Date(data.data.replace(/-/g, "/")));
          // 心跳回复——心跳检测重置
          // 收到心跳检测回复就说明连接正常
          // console.log("收到心跳检测回复");
          // 心跳检测重置
          dispatch('start');
        } else {
          // 普通推送——正常处理
          dispatch('SuccessMessage', data);
          // console.log("收到推送消息");
        }
      };
      //监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
      window.onbeforeunload = () => {
        state.socketObj.close();
      };
    },
    // 处理信息
    SuccessMessage({ commit, state, dispatch, getters, rootState }, data) {
      // 相关处理
      if (data.protocol === 'notFound') {
        console.log('error', data);
      } else {
        if (data.protocol === 'verifySuccess') { // im登录成功
          dispatch('onwsSend', { 'protocol': 'ping' });
        } else if (data.protocol === 'KICK_OUT_SERVER') { // 踢下线
          sessionStorage.removeItem('sessionId')
          sessionStorage.removeItem('state')
          router.push({ path: 'login' })
        } else if (data.protocol === 'ENTER_CHAT_LIST') {// 已读
          if (!data.data) return;
          state.chatList.map(k => {
            if (k.listValue === data.data.listValue) {
              k.notReadCount = 0;
            }
          })
        } else if (data.protocol === 'CHANGE_RED_ENVELOPE') {// 更新群红包状态s
          if (state.chatData.listType && getters.chatId === data.data.chatGroupId) {
            const item = state.chatData.messageList.find((el) => el.redId && el.redId === data.data.redId);
            console.log('CHANGE_RED_ENVELOPE===',item);
            if(item) {
              item.redStatus = data.data.redStatus;
            }
          }
        } else if (data.protocol === 'ADD_FRIEND_APPLY') {// 新好友申请通知
          dispatch('getFriendApplyRecord');
        } else if (data.protocol === 'ADD_FRIEND') {// 新好友
          dispatch('getChatUserFriends');
          if(data.data) {
            state.chatList = [data.data, ...state.chatList]
            dispatch('addDB_ChatList', state.chatList);
          }
        } else if (data.protocol === 'JOIN_GROUP') {// 新群聊
          let chatGroup = data.data.chatGroup;
          state.chatGroups = [chatGroup, ...state.chatGroups];
          let chatList = data.data.chatList;
          let list = [chatList, ...state.chatList]
          dispatch('addDB_ChatList', list);
        } else if (data.protocol === 'DISBAND_GROUP') {// 群解散
          if(getters.chatId === data.data.id) {
            dispatch('getChatGroupUserInfo');
          }
        } else if (data.protocol === 'KICK') {// 被踢出群聊

        } else if (data.protocol === 'GROUP_FORBIDDEN') {// 禁言/取消禁言
          dispatch('getChatGroupUserInfo');
          if (data.data.operateType === 3 || data.data.operateType === 4) {
            let forbiddenFlag = data.data.operateType === 3 ? 1 : 2;
            for (let k of state.chatGroups) {
              if (k.id === data.data.chatGroupId) {
                k.forbiddenFlag = forbiddenFlag
                if (state.chatData.listType && getters.chatId === data.data.chatGroupId) {
                  state.chatData.forbiddenFlag = forbiddenFlag
                }
              }
            }
          }
        } else if (data.protocol === 'CHANGE_GROUP_NAME') { //更新群名称
          if (data.data.chatGroupId === getters.chatId) {
            state.chatData.listName = data.data.name;
          }
        } else if (data.protocol === 'CHANGE_GROUP_AVATAR') { //更新群头像
          if (data.data.chatGroupId === getters.chatId) {
            state.chatData.avatar = data.data.avatar;
          }
        } else if (data.protocol === 'DELETE_GROUP_MESSAGE') { //清空群聊天记录
          const item = state.chatList.find((el) => el.listType && el.listValue === data.data.chatGroupId);
          if (item) {
            if (data.data.msgIdData) { // 撤回一条消息
              if (getters.chatId === data.data.chatGroupId) {
                state.chatData.messageList = state.chatData.messageList.filter(n => { return data.data.msgIdData != n.id });
              }
            } else { // 清空群消息
              if (getters.chatId === data.data.chatGroupId) {
                state.chatData.messageList = [];
              }
              item.notReadCount = 0;
              item.lastMsgContent = '';
            }
          }
        } else if (data.protocol === 'ON_OFF_LINE') {// 好友上下线通知
          for (let k of state.chatList) {
            if (k.listType === 0) {
              if (k.listValue === data.data.userId) {
                k.isOnline = data.data.isOnline
              }
            }
          }
        } else if (data.protocol === 'APPLY_JOIN_GROUP') {// 群申请数量变更
          if (state.chatData.listType && getters.chatId === data.data.chatGroupId) {
            commit("SET_groupApplyReviewCount", data.data.totalApplyCount);
          }
        } else if (data.protocol === 'CHANGE_JOIN_GROUP_TYPE') {// 群入群方式变更
          if (state.chatData.listType && getters.chatId === data.data.chatGroupId) {
            state.chatData.joinType = data.data.joinType;
          }
        } else if (data.protocol === 'SEND_FRIENDS_MESSAGE') { // 私聊消息
          let chatFriendsMessage = data.data.chatFriendsMessage;
          let chatList = data.data.chatList;
          if (getters.chatId && getters.chatId === chatList.listValue) {
            state.chatData.messageList.push(chatFriendsMessage);
          }
          let bool = true;
          // 更新会话列表的lastMsgContent
          state.chatList.map(k => {
            if (k.listValue === chatList.listValue && !k.listType) {
              k.lastMsgContent = chatFriendsMessage.content;
              k.lastMsgTime = chatFriendsMessage.createTime;
              k.notReadCount++;
              bool = false;
            }
          })
          if (bool) {
            let list = [chatList, ...state.chatList]
            dispatch('addDB_ChatList', list);
          }
        } else if (data.protocol === 'SEND_GROUP_MESSAGE') { // 群聊消息
          let messageData = data.data.messageType ? data.data : { ...data.data.message, ...data.data.operateMessage };

          if (messageData.content === 'E531' || messageData.content === 'E532') {// 禁言/解除禁言
            dispatch('getChatGroupUserInfo');
          } else if (messageData.content === 'E529' || messageData.content === 'E530') {// 设置/取消群管理
            dispatch('getChatGroupUserInfo');
          } else if (messageData.content === 'E533' || messageData.content === 'E535') {// 离开群聊
            dispatch('getChatGroupUserInfo');
          }
          if (getters.chatId === messageData.chatGroupId) {
            state.chatData.messageList.push(messageData);
          }
          let bool = true;
          // 更新会话列表的lastMsgContent
          let json = state.chatUserFriends.filter((m) => {
            return m.userId == messageData.sendUserId;
          })[0] || {}
          state.chatList.map(k => {
            if (k.listValue === messageData.chatGroupId && k.listType && !messageData.messageType) {
              k.lastMsgNickName = json.nickName || '';
              k.lastMsgContent = messageData.content;
              k.lastMsgTime = messageData.createTime;
              k.notReadCount++;
              bool = false;
            }
          })
          if (bool) {
            state.chatListFrom.pageNum == 1;
            dispatch('getChatList');
          }
        }
        // console.log('成功', data);
      }
    },
    // websocket重连
    socketReconnect({ commit, state, dispatch }) {
      if (state.socketReconnectLock) {
        return;
      }
      state.wsStatus = 0; //重连中...
      state.socketReconnectLock = true;
      state.socketReconnectTimer && clearTimeout(state.socketReconnectTimer);
      state.socketReconnectTimer = setTimeout(() => {
        console.log("WebSocket:reconnect...");
        state.socketReconnectLock = false;
        // websocket启动
        dispatch('createWebSocket');
      }, 4000);
    },
    // 心跳检测重置
    reset: function ({ commit, state, dispatch }) {
      clearTimeout(state.timeoutObj);
      clearTimeout(state.serverTimeoutObj);
    },
    // 心跳检测启动
    start: function ({ commit, state, dispatch }) {
      clearTimeout(state.timeoutObj);
      clearTimeout(state.serverTimeoutObj);
      state.timeoutObj && clearTimeout(state.timeoutObj);
      state.serverTimeoutObj && clearTimeout(state.serverTimeoutObj);
      state.timeoutObj = setTimeout(() => {
        // 这里向后端发送一个心跳检测，后端收到后，会返回一个心跳回复
        dispatch('onwsSend', { 'protocol': 'ping' });
        // console.log("发送心跳检测");
        state.serverTimeoutObj = setTimeout(() => {
          // 如果超过一定时间还没重置计时器，说明websocket与后端断开了
          // console.log("未收到心跳检测回复");
          // 关闭WebSocket
          state.socketObj.close();
        }, state.timeout);
      }, state.timeout);
    },
    onwsSend({ commit, state, dispatch }, data) {
      state.socketObj.send(JSON.stringify(data));
    },
    denglu({ commit, state, dispatch }) {
      // 给服务器发送一个字符串:
      let data = {
        protocol: "connect",
        token: sessionStorage.getItem('sessionId'),
      };
      dispatch('onwsSend', data);
    },
    setRead({ commit, state, dispatch }, historyId) {
      let data = {
        "protocol": "read",
        "historyId": historyId
      }
      dispatch('onwsSend', data);
    },
    // 获取好友列表
    getChatUserFriends({ commit, state, dispatch }) {
      getChatUserFriends().then((res) => {
        commit("SET_chatUserFriends", res.data || []);
      });
    },
    // 获取群列表
    getChatGroups({ commit, state, dispatch }) {
      getChatGroups().then((res) => {
        commit("SET_chatGroups", res.data || []);
      });
    },
    // 群成员
    getChatGroupUserInfo({ commit, state, dispatch, getters }) {
      let chatGroupId = getters.chatId;
      if (!chatGroupId) {
        return;
      }
      return new Promise((resolve) => {
        chatGroupUserInfo({ chatGroupId: chatGroupId })
          .then((res) => {
            commit("SET_chatGroupUserInfoList", res.data || []);
            resolve();
          }).catch((err) => {
            if( err === 'E512'){
              commit("SET_chatGroupUserInfoList", []);
            }
          })
      });
    },
    // 获取新好友申请列表
    getFriendApplyRecord({ commit, state, dispatch }) {
      friendApplyRecord().then((res) => {
        let data = res.data.records || [];
        commit("SET_newFriendslist", data);
      });
    },
    // 群组申请数量
    onGroupApplyReviewCount({ commit, state, dispatch, getters }) {
      groupApplyReviewCount({ chatGroupId: getters.chatId }).then((res) => {
        commit("SET_groupApplyReviewCount", res.data);
      })
    },
    // 获取聊天列表
    getChatList({ commit, state, dispatch, rootState }) {
      getChatList(state.chatListFrom).then((res) => {
        if (state.chatListFrom.pageNum == 1) {
          state.chatList = [];
          dispatch('addDB_ChatList', state.chatList);
        }
        for (let n of res.data.records) {
          for (let k of state.chatUserFriends) {
            if (k.id == n.listValue && !n.listType) {
              n.listName = k.nickName;
            }
          }
        }
        const listData = [...state.chatList, ...res.data.records];
        state.total = res.data.total;

        state.chatListFrom.loading = false;
        if (listData.length >= state.total) {
          state.chatListFrom.finished = true;
        } else {
          state.chatListFrom.finished = false;
        }
        dispatch('addDB_ChatList', listData);
      }).finally(() => {
        rootState.loadShow = false;
      });
    },
    // ========= 集成 IndexedDB ⬇ =========
    // 创建时初始化数据库
    async createdOpenDB({ commit, state, dispatch, rootState }) {
      state.db = await openDB('myDatabase', 1, {
        upgrade(db) {
          // 如果数据库版本升级（或第一次打开），创建对象存储
          if (!db.objectStoreNames.contains('myChatList')) {
            db.createObjectStore('myChatList', { keyPath: 'id' }); // 创建主键为 'id' 的存储对象
          }
        },
      });
      dispatch('getDB_ChatList');
      dispatch('getChatList');
    },
    // 向数据库中添加（修改）数据
    async addDB_ChatList({ commit, state, dispatch }, listData) {
      const json = {
        id: sessionStorage['uid'],
        total: state.total,
        chatList: JSON.parse(JSON.stringify(listData))
      }
      await state.db.put('myChatList', json);
      dispatch('getDB_ChatList');
    },
    // 从数据库中获取数据
    async getDB_ChatList({ commit, state, dispatch }) {
      const json = {
        id: sessionStorage['uid'],
        chatList: []
      }
      const data = await state.db.get('myChatList', sessionStorage['uid']) || json; // 通过 id 获取数据
      state.chatList = data.chatList;
      state.total = data.total;
    },
    // 从数据库中删除数据
    async delDB_ChatList({ commit, state, dispatch }) {
      await state.db.delete('myChatList', sessionStorage['uid']); // 通过 id 删除数据
      console.log('User deleted'); // 数据删除成功的提示
      dispatch('getDB_ChatList');
    },
    // ========= ⬆ =========
  },
}

export default ws
