import axios from "axios";
import qs from "qs";
import { router } from '../router.js'
import i18n from '../locales/index.js';
import Toast from '../components/Toast/toast.js';
import store from '../store';

axios.defaults.baseURL = process.env.VUE_APP_API
//post请求头
axios.defaults.headers.post["Content-Type"] = "application/json";
//允许跨域携带cookie信息
axios.defaults.withCredentials = true;
//设置超时
axios.defaults.timeout = 30000;
axios.interceptors.request.use(
  config => {
    config.headers[sessionStorage["tokenName"]] = sessionStorage['sessionId'] ? 'Bearer ' + sessionStorage['sessionId'] : ''; // 让每个请求携带自定义token 请根据实际情况自行修改
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  response => {
    if (response.data && response.data.code === 200) {
      return Promise.resolve(response.data);
    } else if (response.data && response.data.code === 400) {
      if (response.data.msg === 'E094' || response.data.msg === 'E095' || response.data.msg === 'E096' || response.data.msg === 'E097' || response.data.msg === 'E098' || response.data.msg === 'E099') {
        Toast(i18n.global.t(response.data.msg));
        sessionStorage.removeItem('sessionId')
        sessionStorage.removeItem('state')
        router.push({ path: 'login' })
        return Promise.reject(response.data.msg);
      } else {
        Toast(i18n.global.t(response.data.msg));
        return Promise.reject(response.data.msg);
      }
    } else {
      Toast(i18n.global.t(response.data.msg));
      return Promise.reject(response.data.msg);
    }
  },
  error => {
    if (error.request.status === 500 || error.request.status === 502) {
      Toast('正在更新');
      return Promise.reject(error.request.status);
    }
    if(error.code === "ECONNABORTED") {
      store.state.loadShow = false;
    }
    console.log(error, 'error');
  }
);
export default {
  /**
   * @param {String} url
   * @param {Object} data
   * @returns Promise
   */
  post(url, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url,
        data: data
      })
        .then(res => {
          if (res) {
            resolve(res)
          }
        })
        .catch(err => {
          reject(err)
        });
    })
  },
  get(url, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url,
        params: data,
      })
        .then(res => {
          if (res) {
            resolve(res)
          }
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  get2(url, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url,
        params: data,
      })
        .then(res => {
          if (res) {
            resolve(res)
          }
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // 上传文件
  form(url, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url,
        data: data,
        headers: {
          'Content-Type': 'multipart/form-data' // 设置请求头以确保正确处理文件上传
        }
      })
        .then(res => {
          if (res) {
            resolve(res)
          }
        })
        .catch(err => {
          reject(err)
        });
    })
  },
};