// store/index.js

import { createStore } from 'vuex';
import { router } from '../router.js'
import axios from "@/util/axios";
import Toast from '../components/Toast/toast.js';
import ws from './modules/ws'

// 设备类型
const userAgent = navigator.userAgent.toLowerCase();
// 判断是否是安卓
const isAndroid = /android/.test(userAgent);
// 判断是否是 iOS
const isIOS = /(iphone|ipad|ipod|ios)/.test(userAgent);
let deviceType = '0';//0 pc 1 android 2 ios 3 android_app 4 ios_app
if (getUrlParam('device')) {
    deviceType = getUrlParam('device');
} else if (isIOS) {
    deviceType = '2';
} else if (isAndroid) {
    deviceType = '1';
}
if (getUrlParam('token')) {
    sessionStorage['sessionId'] = getUrlParam('token');
}
if (getUrlParam('tokenName')) {
    sessionStorage['tokenName'] = getUrlParam('tokenName');
}
if (getUrlParam('parentUrl')) {
    sessionStorage['parentUrl'] = getUrlParam('parentUrl');
}
if (getUrlParam('uid')) {
    sessionStorage['uid'] = getUrlParam('uid');
}
const state = {
    userInfo: {}, // 用户信息
    deviceType: deviceType, // 设备类型
}
const state2 = {
    navActive: 0,
    loadShow: true,
}
const store = createStore({
    state() {
        return sessionStorage.getItem('state') ? Object.assign(JSON.parse(sessionStorage.getItem('state')), state2) : Object.assign(state, state2);
    },
    modules: {
        ws,
    },
    mutations: {
        // 用户信息
        SET_userInfo(state, data) {
            state.userInfo = data;
        },
    },
    actions: {
        // 跳转路由
        toPath({ state, commit, dispatch }, data) {
            if (data) {
                if (data === 'null') {
                    Toast('暂未开放');
                } else {
                    router.push(data);
                }
            } else {
                router.go(-1)
            }
        },
        // 向父页面发送消息
        toParentUrl({ state, commit, dispatch }, bool) {
            // 获取需要传递的参数
            const data = {
                action: "callParentMethod",
                contact: bool || "",
            };
            // 将对象序列化为 JSON 字符串
            const messageString = JSON.stringify(data);
            window.parent.postMessage(messageString, sessionStorage["parentUrl"]);
        },
        // 用户信息
        getUserInfo({ state, commit, dispatch }) {
            axios.post('/cashBack/userInfo').then((res) => {
                commit('SET_userInfo', res.data);
            })
        },
        // 初始化数据
        getInitializeData({ commit, dispatch }) {

        },
        // 更新项目版本
        isNewVersion() {
            let url = `${window.location.origin}/version.json?t=${new Date().getTime()}`;
            axios.get2(url
            ).then(res => {
                if (res.version) {
                    let vueVersion = res.version,
                        localVueVersion = localStorage.getItem('vueVersion');
                    if (localVueVersion && localVueVersion != vueVersion) {
                        // 当前版本有更新，点击确认立即体验
                        localStorage.setItem('vueVersion', vueVersion);
                        window.location.reload();
                        return;
                    }
                }
            })
        },
    },
    getters: {

    }
});
// 获取URL参数
function getUrlParam(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
    var r = window.location.search.substr(1).match(reg); //匹配目标参数
    if (r != null) return unescape(r[2]);
    return null; //返回参数值
}

export default store;
